<template>
  <div class="container">
    <div class="json-content-box">
      <el-table :data="excelData" style="width: 100%" :header-cell-style="tableHeader">
        <el-table-column
          v-for="(value, key, index) in excelData[2]"
          :key="index"
          :prop="key"
          :label="key"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
export default {
  name: "step_4",
  components: {},
  data() {
    return {
      excelData: [],
      workbook: {},
    };
  },
  mounted() {
    // this.value += 1;
    let xlsxPath = this.xlsxPath;
    xlsxPath = "/static/xlsxFile/テスト用 PM契約_画像版.xlsx";
    this.readWorkbookFromRemoteFile(xlsxPath);
  },
  methods: {
    readWorkbookFromRemoteFile: function (url) {
      var xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.responseType = "arraybuffer";
      let _this = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          var data = new Uint8Array(xhr.response);
          var workbook = XLSX.read(data, { type: "array" });
          console.log("workbook", workbook);
          var sheetNames = workbook.SheetNames; // 工作表名称集合
          _this.workbook = workbook;
          _this.getTable(sheetNames[0]);
        }
      };
      xhr.send();
    },
    tableHeader({  rowIndex }) {
      if (rowIndex === 0) {
        return "color:black;font-size:16px;font-weight: 700;border-bottom: 2px #000 solid;";
      }
      return '';
    },
    getTable(sheetName) {
      console.log(sheetName);
      var worksheet = this.workbook.Sheets[sheetName];
      this.excelData = XLSX.utils.sheet_to_json(worksheet);
      console.log(this.excelData);
    },
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: Number,
    jsonContent: String,
    xlsxPath: String,
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .json-content-box {
    border: 1px #f5f5f5 solid;
    min-height: 100vh;
    width: 100%;
    box-shadow: -1px 3px 9px 0px #f5f5f5;
    border-radius: 7px;
    
  }
}
</style>