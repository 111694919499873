<template>
  <div class="container">
    <div
      class="drop_area mg-b-20 disFlex align-center hor-center"
      id="drop_areas"
      :style="{'border-color': (borderhover ? '#3d8cff':'#BBBBBB')}"
    >
      <div style>
        <div class="lang" v-html="lan.drag_or_drop_here"></div>
      </div>
      <div>
        <div class="font-blue pos-r cur-hand mg-l-10" style="top: 12px;">
          <i class="el-icon-upload mg-r-5"></i>
          <div class="lang" v-html="lan.upload_file"></div>
        </div>
      </div>

      <input
        type="file"
        accept="application/xlsx"
        id="uploadfile"
        class="input-upload cur-hand"
        @change="uploadFile($event.target.files)"
      />
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  name: "step_1",
  components: {},
  data() {
    return {
      lan: inject("global").language_data,
      finfo: {
        tags: [],
        name: "",
        remark: "",
        fid: 1,
      },
      filepond: {},
      tag_input: "",

      fileData: [],
    };
  },
  mounted() {},
  methods: {
    uploadFile(e) {
      //渲染上传文件
      var file = e;
      console.log(file);
      for (let i = 0; i !== file.length; i++) {
        let fileJson = {
          Url: "",
          progressStatl: 0,
          fileText: "",
        };
        let video_type = file[i].name.indexOf(".pdf") > 0;
        //如果是图片
        let fileurl = window.URL.createObjectURL(file[i]); //创建一个url连接,供src属性引用
        fileJson.Url = fileurl;
        if (!video_type) {
          alert("Can not support file,only pdf");
          return false;
        }
        fileJson.fileText = file[i].name;
        this.finfo.name = file[i].name;

        this.fileData = [fileJson];
        this.filepond = file[0];
        console.log(this.finfo);
        console.log(this.fileData);
        console.log(this.filepond);
        // this.SubmitFile();
        this.returnFile(file[0]);
        this.value += 1;
        // this.$emit('next',this.modelValue)
      }
    },
    returnFile(file){
      this.$emit('getUploadFile',file);
    }
  },
  emits: ["update:modelValue","getUploadFile"],
  props: {
    modelValue: Number,
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.google {
  height: 800px;
}
.overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 60px;
  line-height: 80px;
  opacity: 0.8;
  background: #4477aa;
  border: solid 3px #336699;
  border-radius: 4px;
  box-shadow: 2px 2px 10px #333;
  text-shadow: 1px 1px 1px #666;
  padding: 0 4px;
}
.overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}
.overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #336699;
}
.overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #336699;
}
.row {
  font-size: 14px;
}
pre {
  font-family: "Ubuntu Mono";
  font-size: 14px;
}
.row {
  zoom: 1;
  margin-left: -20px;
}
.row:before,
.row:after {
  display: table;
  content: "";
  zoom: 1;
}
.row:after {
  clear: both;
}
.row > [class*="span"] {
  display: inline;
  float: left;
  margin-left: 20px;
}
.span1 {
  width: 40px;
}
.span2 {
  width: 100px;
}
.span3 {
  width: 160px;
}
.span4 {
  width: 220px;
}
.span5 {
  width: 280px;
}
.span6 {
  width: 340px;
}
.span7 {
  width: 400px;
}
.span8 {
  width: 460px;
}
.span9 {
  width: 520px;
}
.span10 {
  width: 580px;
}
.span11 {
  width: 640px;
}
.span12 {
  width: 700px;
}
.span13 {
  width: 760px;
}
.span14 {
  width: 820px;
}
.span15 {
  width: 880px;
}
.span16 {
  width: 940px;
}
.span17 {
  width: 1000px;
}
.span18 {
  width: 1060px;
}
.span19 {
  width: 1120px;
}
.span20 {
  width: 1180px;
}
.span21 {
  width: 1240px;
}
.span22 {
  width: 1300px;
}
.span23 {
  width: 1360px;
}
.span24 {
  width: 1420px;
}
pre.prettyprint {
  background: #fff;
  border-color: #c1c1c1;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 10px #999;
  -moz-box-shadow: 0 0 10px #999;
  box-shadow: 0 0 10px #999;
  padding: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.label {
  font-size: 10.998px;
  font-weight: bold;
  line-height: 14px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999999;
  margin: 2px 6px;
}
.label {
  padding: 1px 4px 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.notice {
  background-color: #3a87ad;
}
form input[type="text"] {
  border: solid 1px #999999;
  padding: 2px;
}
.edit_marker {
  width: 250px;
  height: 80px;
}
.edit_marker p {
  margin: 0;
}
.address_list {
  position: absolute;
  top: 80px;
  left: 30px;
  z-index: 999;
  background: #fff;
  max-height: 500px;
  overflow-y: auto;
}
.address_list .line {
  border-top: 1px solid #eee;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
}
.address_list .line:nth-child(0) {
  border-top: 0;
}
.address_list .active {
  background: #003f2d;
  color: #fff;
}
.nav-tabs li {
  cursor: pointer;
}

.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {
  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: 0.75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: 0.5em;
    width: 100%;
  }
}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: 0.15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -0.5rem;
  top: -0.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 32.5%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .dropdown-menu.show li {
    flex-basis: 50%;
  }
}

@media screen and (max-width: 500px) {
  .dropdown-menu.show li {
    flex-basis: 100%;
  }
}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover,
table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -0.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table.table-striped td {
  min-width: 14.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: 0.35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: 0.15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}
.table td .table-data-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}
.types {
  display: table;
  margin: 0 auto;
}
.drop_area {
  width: 100%;
  text-align: center;
  position: relative;
  display: inline-block;
  height: 150px;
  line-height: 150px;
  border: 1px #ced4da dashed;
}
.drop_area div {
  display: inline-block;
}
.input-upload {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.font-blue {
  color: #012a2d;
}
.cur-hand {
  cursor: pointer;
}
.filelist {
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-top: 10px;
}
.filelist li {
  list-style: none;
}
.line {
  margin: 5px 1%;
  width: 98%;
  display: inline-block;
}
.line .left-box {
  font-weight: bolder;
}
.line input {
  width: 300px;
}
.left-box {
  width: 120px;
  float: left;
}
.title,
h4 {
  color: #003f2d;
}
.right-box {
  margin-left: 3%;
  width: 60%;
  float: left;
  position: relative;
}
.content {
  background: #fff;
  padding: 20px;
  position: relative;
}
.btn {
  color: #fff;
  background: #042a2d;
  margin: 10px 20px;
}
.btn-bottom {
  margin: 50px auto 0 auto;
}
.bleft {
  width: 48%;
  margin: 1%;
  float: left;
  display: inline-block;
}
.company_box {
  position: absolute;
  z-index: 999;
  top: 26px;
  left: 0;
  height: 260px;
  border: 1px solid #ccc;
  background: #fff;
  width: 550px;
}
.company_box .cline {
  width: 98%;
  margin: 0 1%;
  line-height: 25px;
  cursor: pointer;
  border-top: 1px solid #ccc;
}
.company_box .cline:nth-child(1) {
  border: 0;
}
.company_box .cline:hover {
  color: #003f2d;
}
.error {
  border: 1px solid #ff0000;
}
</style>