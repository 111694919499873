<template>
  <div class="container">
    <div>
      <button class="btn" @click="ocr">OCR</button>
    </div>
    <div>
      <iframe :src="pdfUrl" class="pdf-viewer" /> 
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name:"step_2",
  components:{},
  data(){
    return{
      
    }
  },
  mounted(){},
  methods:{
    ocr(){
      axios.get("/static/jsonFile/テスト用 PM契約_画像版.json",{
        baseURL:''
      }).then(res=>{
        let jsonList = res.data.data;
        console.log("本地json:",res.data.data);
      //   let jsonString = ``;
      //   jsonList.forEach(element => {
      //     if(element.isArray){
      //       element.forEach(item=>{
      //         jsonString += item;
      //       })
      //       return;
      //     }
      //     jsonString += element;
      //   });
      this.value += 1;
      // let ocrContent = jsonString;
      this.$emit('getPdfContent',jsonList);
      }).catch(err=>{
        console.log(err);
        alert('error');
      });
      
    }
  },
  emits: ["update:modelValue","getPdfContent"],
  props: {
    modelValue: Number,
    filePath:String,
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    pdfUrl(){
      console.log("显示路径:",this.filePath)
      return `/static/pdf/web/viewer.html?file=${this.filePath}`
    }
  },

}
</script>

<style scoped lang="scss">
.btn {
  color: #fff;
  background: #042a2d;
  margin: 10px 20px;
}
.container{
  div:nth-child(1){
    text-align: right;
    margin-bottom: 20px;
  }
  .pdf-viewer{
    width: 100%;
    min-height: 100vh;
  }
  
}
</style>