<template>
  <div class="container">
    <div class="read-file-box">
      <button class="read-file-btn btn" @click="readFile">Read File</button>
    </div>
    <div class="pdf-text-box">
      <json-viewer :value="pdfContent" :boxed="true" theme="btn"></json-viewer>
    </div>
  </div>
</template>

<script>

export default {
  name: "step_3",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    readFile() {
      this.value += 1;
      let pdfJsonContent = `{"name":"fmmm","age":87}`;
      this.$emit("getPdfJsonContent", pdfJsonContent);
    },
  },
  emits: ["update:modelValue", "getPdfJsonContent"],
  props: {
    modelValue: Number,
    pdfContent: Array,
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">

.btn {
  color: #fff;
  background: #042a2d;
  margin: 10px 20px;
}
.container {
  .read-file-box {
    text-align: right;
    .read-file-btn {
      margin-bottom: 20px;
    }
  }

  .pdf-text-box {
    border: 2px #f5f5f5 solid;
    min-height: 100vh;
    box-shadow: -1px 3px 9px 0px #f5f5f5;
    border-radius: 7px;
  }
}
</style>